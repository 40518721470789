<template>
  <div class="pageContent">
    <img :src="module1" alt="" />
    <img :src="module2" alt="" />
    <img :src="module3" alt="" />
    <img :src="module4" alt="" />
  </div>
  <Footer />
</template>
<script>
import { onMounted, ref, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Footer from '@/components/Footer.vue';
import module1 from '@/assets/images/home/1.png';
import module2 from '@/assets/images/home/2.png';
import module3 from '@/assets/images/home/3.png';
import module4 from '@/assets/images/home/4.png';

export default {
  components: {
    Footer
  },
  setup() {
    return {
      module1,
      module2,
      module3,
      module4
    };
  }
};
</script>
<style lang="scss" scoped>
.pageContent {
  display: flex;
  flex-direction: column;
  > img {
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }
}
</style>
