<template>
  <!-- <footer class="footer_content" id="contentUs">
    <div class="color_bar">
      <h2>Solicita tu Préstamo Online</h2>
      <h6>Una solicitud más ágil, complétala hoy y obtén hasta S/ 10,000 sin salir de casa.</h6>
    </div>
    <div class="footer_box">
      <div class="footer_detail">
        <div class="footer_logo">
          <img src="../assets/images/home/footer_logo.png" alt="" style="height: 42px" />
        </div>
        <div class="footer_text">
          <div style="width: 40%">
            <h4 style="cursor: pointer" @click="goTo('https://cococreditos.com/terminos-y-condiciones')">Términos y Condiciones</h4>
            <h4 style="cursor: pointer" @click="goTo('https://cococreditos.com/politica-de-privacidad')">Política de Privacidad</h4>
          </div>
          <div style="width: 50%">
            <h4>Contáctanos</h4>
            <div class="contact">
              <span><img src="../assets/images/home/email_icon.png" alt="" /></span>
              <p style="cursor: pointer" @click="goTo('mailto:cococredito@gmail.com')">cococredito@gmail.com</p>
            </div>
          </div>
        </div>
        <div class="footer_copyRight">Amour Fer, INC © people préstamos 2023</div>
      </div>
    </div>
  </footer> -->
  <div class="footer-wrap">
    <div class="footer-wrap-content">
      <div class="footer-wrap-content-item">
        <div class="footer-wrap-content-item-header">
          <img src="../assets/logo.png" alt="" />
          <span
            >People<br />
            Préstamos</span
          >
        </div>
        <div>Email: support@people@gmail.com</div>
        <div class="privacy-terms">
          <span @click="onPush('/politica-de-privacidad')">Política de privacidad</span>
          <span @click="onPush('/terminos-y-condiciones')">Términos y condiciones</span>
        </div>
      </div>
      <div class="footer-wrap-content-item"></div>
      <div class="footer-wrap-content-item"></div>
    </div>
    <div class="footer-wrap-content">Amour Fer, INC © People Préstamos 2025</div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'FFooter',
  setup(params, ctx) {
    const router = useRouter();
    const onPush = path => {
      router.push(path);
    };
    const goPost = () => {
      const doc = document.documentElement || document.body;
      let distance = doc.scrollTop; //获得当前高度
      (function jump() {
        let step = distance / 5; //每步的距离
        if (distance > 0) {
          distance -= step;
          distance = distance < 1 ? 0 : distance;
          window.scrollTo(0, distance);
          window.requestAnimationFrame(jump);
        }
      })();
    };
    const goTo = href => {
      // window.location.href = href;
      window.open(href, '_blank');
    };
    return {
      goPost,
      goTo,
      onPush
    };
  }
};
</script>
<style lang="scss" scoped>
@media all and (min-width: 1021px) {
  .footer_content {
    width: 100%;
    min-width: 1000px;
    position: relative;
    margin-top: 78px;

    .color_bar {
      width: 100%;
      height: 131px;
      background: linear-gradient(90.2deg, rgba(255, 92, 87, 1) 0.15%, rgba(255, 197, 133, 1) 100.06%);
      padding-top: 30px;

      h2 {
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-size: 32px;
      }

      h6 {
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-size: 16px;
        margin-top: 10px;
      }
    }

    .footer_box {
      width: 100%;
      height: 280px;
      background: rgba(255, 230, 214, 1);

      .footer_detail {
        width: 1020px;
        margin: 0 auto;
        padding-top: 30px;

        .footer_logo {
          width: 250px;
          height: 33px;

          img {
            width: 250px;
            height: 33px;
          }
        }

        .footer_text {
          width: 100%;
          margin-top: 35px;
          display: flex;
          justify-content: space-between;

          h4 {
            color: rgba(51, 51, 51, 1);
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            font-size: 18px;
            line-height: 30px;
          }

          .contact {
            margin-top: 8px;

            span {
              display: inline-block;
              width: 28px;
              height: 26px;
              vertical-align: middle;
              margin-right: 10px;

              img {
                width: 26px;
                height: 26px;
              }
            }

            p {
              display: inline-block;
              color: rgba(0, 0, 0, 1);
              font-family: Alibaba PuHuiTi;
              font-weight: 500;
              font-size: 14px;
            }
          }
        }

        .footer_copyRight {
          text-align: center;
          color: rgba(0, 0, 0, 1);
          font-family: Alibaba PuHuiTi;
          font-size: 14px;
          margin-top: 32px;
        }
      }
    }
  }
}

@media all and (max-width: 1020px) {
  .footer_content {
    width: 100%;
    // min-width: 1000px;
    position: relative;
    margin-top: 78px;
    overflow: hidden;
    .color_bar {
      width: 100%;
      height: 131px;
      background: linear-gradient(90.2deg, rgba(255, 92, 87, 1) 0.15%, rgba(255, 197, 133, 1) 100.06%);
      padding-top: 30px;

      h2 {
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-size: 32px;
      }

      h6 {
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-size: 16px;
        margin-top: 10px;
      }
    }

    .footer_box {
      width: 100%;
      height: 280px;
      background: rgba(255, 230, 214, 1);

      .footer_detail {
        // width: 1020px;
        margin: 0 auto;
        padding-top: 30px;

        .footer_logo {
          // width: 150px;
          // height: 33px;

          img {
            // width: 150px;
            // height: 33px;
          }
        }

        .footer_text {
          width: 100%;
          margin-top: 35px;
          display: flex;
          justify-content: space-between;

          h4 {
            color: rgba(51, 51, 51, 1);
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            font-size: 18px;
            line-height: 30px;
          }

          .contact {
            margin-top: 8px;

            span {
              display: inline-block;
              width: 28px;
              height: 26px;
              vertical-align: middle;
              margin-right: 10px;

              img {
                width: 26px;
                height: 26px;
              }
            }

            p {
              display: inline-block;
              color: rgba(0, 0, 0, 1);
              font-family: Alibaba PuHuiTi;
              font-weight: 500;
              font-size: 14px;
            }
          }
        }

        .footer_copyRight {
          text-align: center;
          color: rgba(0, 0, 0, 1);
          font-family: Alibaba PuHuiTi;
          font-size: 14px;
          margin-top: 32px;
        }
      }
    }
  }
}

.footer-wrap {
  padding: 30px 190px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 100px;
  max-width: 2880px;
  height: auto;
  margin: 0 auto;
  background-color: #f38972;
  box-sizing: border-box;
  &-content {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 200px;
    &-item {
      min-width: 200px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      &-header {
        display: flex;
        align-items: center;
        gap: 38px;
        > img {
          width: 67px;
          height: 51px;
        }
        > span {
          font-size: 25px;
          font-family: 'Bespoke Serif';
        }
      }
      .privacy-terms {
        color: #ffb94f;
        font-family: Helvetica;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        text-decoration-line: underline;
        display: flex;
        gap: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>
