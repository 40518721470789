<template>
  <div class="body">
    <!-- <Header /> -->
    <router-view v-if="isRouterAlive" />
    <!-- <Footer /> -->
  </div>
</template>

<script>
import { useStore } from 'vuex';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { onBeforeMount, ref } from '@vue/runtime-core';
import { onMounted, provide, nextTick, watch } from 'vue';
import { useRoute } from 'vue-router';
// 刷新
const onReload = () => {
  const isRouterAlive = ref(true);
  const reload = async () => {
    isRouterAlive.value = false;
    await nextTick();
    isRouterAlive.value = true;
  };
  return { isRouterAlive, reload };
};
export default {
  components: {
    Header,
    Footer
  },
  setup() {
    const store = useStore();
    const state = ref('pc');
    const { isRouterAlive, reload } = onReload();
    provide('reload', reload);
    const setMode = () => {
      let w = document.documentElement.clientWidth;
      if (w > 960) {
        state.value = 'pc';
        store.state.client === 'mobile' ? store.commit('setWidth', 'pc') : null;
        return;
      }
      state.value = 'mobile';
      store.state.client === 'pc' ? store.commit('setWidth', 'mobile') : null;
    };
    onBeforeMount(setMode);
    window.addEventListener('resize', setMode);
    return {
      state,
      isRouterAlive
    };
  }
};
</script>

<style lang="scss">
.body {
  min-height: 100vh;
  transition: background-color 0.3s linear;
}
* {
  padding: 0;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
</style>
